<template>
  <spin-container :loading="bodyLoading">
    <Container class="pt-8" size="3xl">
      <h1>{{ form_name }}</h1>
      <Form
        v-if="aoFields && !saving && !submitted"
        @submit="onSubmit"
        :aoFields="aoFields"
        :aoFieldOptions="aoFieldOptions"
      />
      <div class="flex flex-col justify-center items-center" v-if="saving">
        <LoadingIndicator />
        <p class="text-xl mt-4">{{ l('Saving form submission') }}</p>
      </div>
      <div class="flex flex-col justify-center items-center" v-if="submitted">
        <font-awesome-icon icon="circle-check" class="check !h-28 !w-28 text-matcha-500 mb-12" />
        <h1 class="mb-4">{{ l('Submission Successful') }}</h1>
        <div class="text-lg">
          {{ l('Thank you for your submission! We will get back to you as soon as possible') }}.
        </div>
      </div>
    </Container>
  </spin-container>
</template>

<script>
import { ref } from 'vue'
import { useStore } from 'vuex'
import ObjectManipulator from '@/components/mixins/ObjectManipulator'
import BodyMixin from '@/components/mixins/Body'
import Form from '@/components/forms/Form.vue'
import LoadingIndicator from '@/components/ui/LoadingIndicator.vue'
import useForm from '@/components/forms/Form'
import { useRoute } from 'vue-router'
import useTranslation from '@/components/composables/Translation'

export default {
  name: 'FormBody',
  mixins: [ObjectManipulator('form', false), BodyMixin /* , AutoSave */],
  setup() {
    const { saveCustomFieldValues } = useForm()
    const { l } = useTranslation()
    const $route = useRoute()
    const $store = useStore()
    const saving = ref(false)
    const submitted = ref(false)

    const onSubmit = async (fields) => {
      saving.value = true
      try {
        await saveCustomFieldValues(fields, $route.params.id)
        submitted.value = true
      } catch (e) {
        $store.dispatch('alert', { error: true, message: e.message }, { root: true })
      }
      saving.value = false
    }

    return {
      saving,
      submitted,
      onSubmit,
      l
    }
  },
  data() {
    return {}
  },
  watch: {},
  computed: {},
  methods: {},
  components: {
    Form,
    LoadingIndicator
  },
  props: {},
  mounted() {},
  beforeUnmount() {}
}
</script>
