<template>
  <FormBody ref="body" />
</template>

<script>
import PageMixin from '@/components/mixins/Page'
import FormBody from '@/components/bodies/Form.vue'

export default {
  mixins: [PageMixin],
  name: 'FormPage',
  data() {
    return {
      loading: 0,
      isDirty: 0
    }
  },
  methods: {
    onSubmit() {}
  },
  components: {
    FormBody
  }
}
</script>
